<template>
	<!-- eslint-disable max-lines-->
	<ValidationForm
		#default="{ handleSubmit }"
		class="cassie-vertical-md"
		tag="div"
	>
		<SiteScannerLayout
			header-title="Scan your Website"
			header-caption="Request a scan and receive a report on cookies used by your website"
		>
			<template #content>
				<SectionCard>
					<template #title>
						Scan Details
					</template>
					<template #subtitle>
						Input a Name for the Scan, assign a Brand and an Email Address to send updates to
					</template>
					<template #body>
						<v-row dense>
							<v-col
								v-if="siteScannerV2"
								cols="6"
							>
								<TextField
									v-model="siteScan.scanName"
									:disabled="!siteScannerFullAccess"
									label="Scan Name *"
									:rules="{required:true, max:255}"
									tooltip-text="Enter a name for the scan."
								/>
							</v-col>
							<v-col cols="6">
								<Dropdown
									v-model="siteScan.brandId"
									label="Brand *"
									:disabled="!siteScannerFullAccess || !clientMultiBranded || singleBrand"
									rules="required"
									searchable
									custom-sort
									:items="brandOptions"
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="6">
								<TextField
									v-model="siteScan.emailAddress"
									:disabled="!siteScannerFullAccess"
									label="Email Address *"
									:rules="{required:true, email:true}"
									tooltip-text="Cassie will use this Email Address to update once the scan results are ready to be retrieved.
																The Email Address should be a Cassie Admin Portal User with the relevant access rights to view these reports."
								/>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
				<SectionCard>
					<template #title>
						Scan Options
					</template>
					<template #subtitle>
						Select a type of scan. Options include a URL Scan, a Sitemap URL or upload a Sitemap File
					</template>
					<template #body>
						<v-row dense>
							<v-col cols="6">
								<Dropdown
									v-model="siteScan.scanType"
									:disabled="!siteScannerFullAccess"
									label="Type of Scan"
									:items="scanTypeItems"
									tooltip-text="Select a Cookie Scan type"
								/>
							</v-col>
							<!-- single url -->
							<v-col
								v-if="siteScan.scanType === singleUrlScanType"
								cols="6"
							>
								<TextField
									v-model="siteScan.domain"
									:disabled="!siteScannerFullAccess"
									label="Single URL *"
									:rules="{required:true, isUrlwithHttp:true}"
									tooltip-text="Beginning with http:// or https://, please enter the URL for the page you wish to scan."
								/>
							</v-col>
							<!-- sitemap url -->
							<v-col
								v-if="siteScan.scanType === sitemapUrlScanType"
								cols="6"
							>
								<TextField
									v-model="siteScan.sitemapUrl"
									:disabled="!siteScannerFullAccess"
									label="Nominate your site map URL *"
									:rules="{required:true, isUrlwithHttp:true}"
									tooltip-text="Beginning with http:// or https://, please enter the URL of the sitemap you wish to scan"
								/>
							</v-col>
							<!-- sitemap file -->
							<v-col
								v-if="siteScan.scanType === sitemapFileScanType"
								cols="6"
							>
								<!-- file input -->
								<FileInput
									label="Upload site map XML file *"
									:disabled="!siteScannerFullAccess"
									:select-file-only="true"
									tooltip-text="Upload your Sitemap.xml file."
									accept=".xml"
									@selectFile="selectFile"
								/>
							</v-col>
							<v-col
								v-if="siteScan.scanType === indexedSitemapUrlScanType"
								cols="6"
							>
								<!-- file input -->
								<FileInput
									label="Upload Indexed Sitemap XML File *"
									:disabled="!siteScannerFullAccess"
									:select-file-only="true"
									tooltip-text="Upload your indexed sitemap.xml file."
									accept=".xml"
									@selectFile="selectFile"
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col
								v-if="siteScan.scanType === singleUrlScanType"
								cols="6"
							>
								<Toggle
									label="Limit To This URL"
									tooltip-text="Enable this feature when scanning a particular page or area in your site."
									:value.sync="siteScan.deepCrawl"
								/>
							</v-col>
							<v-col cols="6">
								<div class="d-flex flex-row">
									<Dropdown
										v-model="siteScan.pausePeriodPerUrl"
										:disabled="!siteScannerFullAccess"
										:items="pausePeriodItems"
										custom-sort
										label="Pause period per URL"
										tooltip-text="A 'Pause Period' halts the Scanner from moving on to the next URL for the given time period.  This is not applicable where a scan is limited to a single URL"
									/>
									<div class="d-flex align-center mx-2">
										Seconds
									</div>
								</div>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
				<SectionCard>
					<template #title>
						Scan Schedule
					</template>
					<template #subtitle>
						Set a scan to run now, run once on a specified date or to run a recurring basis
					</template>
					<template #body>
						<v-row
							dense
						>
							<v-col cols="6">
								<Dropdown
									v-model="siteScan.scanSchedule"
									:disabled="!siteScannerFullAccess"
									label="Schedule Scan"
									:items="scanScheduleItems"
									@change="onScheduleScanChanged($event)"
								/>
							</v-col>
							<v-col
								v-if="siteScan.scanType !== singleUrlScanType"
								cols="6"
							>
								<div class="d-flex flex-row">
									<DatePicker
										:date="scanExcludeBeforeDate"
										:max="today"
										:disabled="!siteScannerFullAccess"
										rules="required"
										label="Exclude Before Date"
										@update:date="updateScanExcludeBeforeDate($event)"
									/>
									<v-tooltip
										slot="append"
										top
									>
										<template #activator="{ on }">
											<v-icon
												light
												v-on="on"
											>
												mdi-information
											</v-icon>
										</template>
										<span>
											Select a Date which Cassie will use to determine which Sitemap URLs will not be scanned based on the 'Last Modified' date.
											The Scanner will only scan pages which state in the Sitemap they have been modified after this date.
										</span>
									</v-tooltip>
								</div>
							</v-col>
						</v-row>
						<v-row v-if="siteScan.scanSchedule === scheduleSingleScanValue">
							<v-col
								cols="6"
								class="cassie-vertical-md"
							>
								<div class="d-flex flex-row">
									<div class="d-flex align-center mr-2">
										Scan on
									</div>
									<DatePicker
										:date="scanFromDate"
										:min="today"
										:disabled="!siteScannerFullAccess"
										class="cassie-input-width-sm"
										rules="required"
										@update:date="updateScanFromDate($event)"
									/>
									<div class="d-flex align-center mx-2">
										at
									</div>
									<TextField
										v-model="siteScan.scanRunTime"
										label="Scan Run Time *"
										:rules="{required:true, regex: time24regex}"
										tooltip-text="Enter the time in 24 hour clock format hh:mm"
									/>
								</div>
							</v-col>
						</v-row>
						<v-row
							v-if="siteScan.scanSchedule === scheduleSingleScanValue"
							dense
						>
							<v-col cols="12">
								<div>
									Based on your selections, this scan will be scheduled for {{ scanDateFormatted }} {{ serverTimeInfo.serverTimezone }}
								</div>
								<br>
								<div>
									It is currently {{ currentServerTimeFormatted }} in {{ serverTimeInfo.serverTimezone }}
								</div>
							</v-col>
						</v-row>
						<v-row
							v-if="siteScan.scanSchedule === scheduleRecurringScanValue"
							dense
						>
							<v-col cols="6">
								<Dropdown
									v-model="siteScan.scanFrequency"
									label="Scan Frequency"
									:items="scanFrequencyItems"
									custom-sort
								/>
							</v-col>
							<v-col
								v-if="weeklyScanFrequency"
								cols="3"
							>
								<Dropdown
									v-model="siteScan.scanDayOfWeek"
									label="Day of Week"
									:items="dayItems"
									custom-sort
									:disabled="!siteScannerFullAccess"
								/>
							</v-col>
							<v-col
								v-if="monthlyScanFrequency || quarterlyScanFrequency"
								cols="3"
							>
								<Dropdown
									v-model="siteScan.scanDayOfMonth"
									label="Day of Month"
									:items="dayOfMonthItems"
									custom-sort
									:disabled="!siteScannerFullAccess"
								/>
							</v-col>
							<v-col
								cols="3"
							>
								<TextField
									v-model="siteScan.scanRunTime"
									label="Scan Run Time *"
									:rules="{required:true, regex: time24regex}"
									tooltip-text="Enter the time in 24 hour clock format hh:mm"
								/>
							</v-col>
						</v-row>
						<v-row
							v-if="siteScan.scanSchedule === scheduleRecurringScanValue"
						>
							<v-col cols="3">
								<DatePicker
									:date="scanFromDate"
									:min="today"
									:disabled="!siteScannerFullAccess"
									label="Don't Run Before"
									class="cassie-input-width-sm"
									rules="required"
									@update:date="updateScanFromDate($event)"
								/>
							</v-col>
						</v-row>
						<v-row
							v-if="siteScan.scanSchedule === scheduleRecurringScanValue"
							dense
						>
							<v-col cols="12">
								<div>
									Based on your selections, your 1st scan will be scheduled for {{ dateStringWithTimezone(firstScanDate) }}
								</div>
								<br>
								<div>
									It is currently {{ currentServerTimeFormatted }} in {{ serverTimeInfo.serverTimezone }}
								</div>
							</v-col>
						</v-row>
						<v-row
							v-if="maxUrls != null && maxUrls != 0 && !siteScan.deepCrawl"
							dense
						>
							<v-col cols="6">
								This scan will crawl up to <strong>
									{{ maxUrls }}
								</strong> pages.
							</v-col>
						</v-row>
					</template>
				</SectionCard>
				<SectionCard>
					<template #title>
						Advanced Features - Optional
					</template>
					<template #title-action>
						<Toggle
							:value.sync="showAdvancedFeatures"
							label="Show"
							label-left
						/>
					</template>
					<template
						v-if="showAdvancedFeatures"
						#body
					>
						<v-row dense>
							<v-col cols="12">
								<span class="font-weight-bold">
									Cookie Banner Interaction -
								</span>
								This allows the Scanner to click a specified button on the Cookie Banner prior to each URL being scanned
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="6">
								<TextField
									v-model="siteScan.buttonSelector"
									:disabled="!siteScannerFullAccess"
									label="Button Class or ID"
									:tooltip-text="`<p>Specify the Class or ID of the button you are looking to click on your Cookie Banner.</p>
																	<p>Class fields should be prefixed with '.' e.g. '.accept_all_cookies'</p>
																	<p>ID fields should be prefixed with '#' e.g. '#reject_all_cookies</p>`"
								/>
							</v-col>
						</v-row>
						<v-divider />
						<v-row dense>
							<v-col cols="12">
								<span class="font-weight-bold">
									Specify Domains -
								</span>
								If the scan is going to contain multiple domains, they can be listed here to help with any potential redirect conflicts
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12">
								<!-- tbc :selected value -->
								<OptionSelector
									:selected="siteScan.allowedDomains"
									entity-type="Domain"
									is-text
									:disabled="!siteScannerFullAccess"
									@update:selected="updateAllowedDomains($event)"
								/>
							</v-col>
						</v-row>
						<v-divider />
						<v-row dense>
							<v-col cols="6">
								<div class="d-flex flex-row">
									<Dropdown
										v-model="siteScan.pageTimeout"
										label="Page Timeout"
										:disabled="!siteScannerFullAccess"
										tooltip-text="This is defaulted to 5 seconds but can be customised and set between 1 and 10 seconds."
										custom-sort
										:items="pageTimeoutItems"
									/>
									<div class="d-flex align-center mx-2">
										Seconds
									</div>
								</div>
							</v-col>
							<v-col
								v-if="showRobotsTxtToggle"
								cols="6"
							>
								<Toggle
									label="Follow 'robots.txt' guidance?"
									:value.sync="siteScan.followRobotsTxt"
									:disabled="!siteScannerFullAccess"
									tooltip-text="Enabling this will instruct the Scanner to follow any rules set in the 'robots.txt' file which manages crawl traffic.'"
								/>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="6">
								<div class="d-flex flex-row">
									<Toggle
										label="Use Headless Mode"
										:value.sync="siteScan.headless"
										:disabled="!siteScannerFullAccess"
										tooltip-text="By default, the scanner will use headless mode. Disabling this will use more resources and make the scan take longer, but may resolve issues with certain websites that block automated traffic."
									/>
								</div>
							</v-col>
						</v-row>
						<v-divider />
						<template v-if="loginTemplateItems.length > 0">
							<div>
								<span class="font-weight-bold">
									Scan Behind a Login Screen -
								</span>
								By selecting a pre configured Cassie Login Template, the Cassie Site Scanner will be able to login to a selected authentication page prior to scanning.
							</div>
							<v-row>
								<v-col>
									<Dropdown
										v-model="siteScan.loginTemplate"
										label="Login Template"
										:items="loginTemplateItems"
										clearable
										:disabled="!siteScannerFullAccess"
									/>
								</v-col>
							</v-row>
							<div v-if="siteScan.loginTemplate">
								<span class="font-weight-bold">
									Description -
								</span>
								{{ loginTemplateDescription }}
							</div>
						</template>
					</template>
				</SectionCard>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="backToOverview">
							Back to Overview
						</SecondaryActionButton>
						<v-spacer />
						<PrimaryActionButton
							v-if="siteScannerFullAccess"
							@click="handleSubmit(requestSiteScan)"
						>
							Request Scan
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</SiteScannerLayout>
	</ValidationForm>
</template>
<script>
/* eslint-disable max-lines */
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import SiteScannerLayout from './site-scanner-layout.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import ValidationForm from '../../../../../../shared/components/validation-form.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import PageActionRow from '../../../../../../shared/components/page-action-row.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import FileInput from '../../../../../../shared/components/file-input.vue'
import DatePicker from '../../../../../../shared/components/date-picker.vue'
import Toggle from '../../../../../../shared/components/toggle.vue'
import OptionSelector from '../../../../../../shared/components/option-selector.vue'
import { requestScheduledScan } from '../../../../../../shared/utils/api/site-scanner.js'
import { clientMultiBranded, brandOptions, singleBrand, defaultBrandInSelect, waitForLoad } from '../../../../../../shared/state/brands.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { SITE_SCANNER_FULL_ACCESS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import { DATE_FORMAT_DATE_PICKER, DATE_TIME_FORMAT_NO_SECONDS } from '../../../../../../shared/utils/date-formatting.js'
import { getServerTimezoneInfo } from '../../../../../../shared/utils/api/server-timezone.js'
import { siteScannerVersion } from '../../../../../../shared/state/config-keys.js'

const today = format(new Date(), DATE_FORMAT_DATE_PICKER)

export default {
	components: {
		SiteScannerLayout,
		SectionCard,
		ValidationForm,
		PrimaryActionButton,
		PageActionRow,
		SecondaryActionButton,
		TextField,
		DatePicker,
		Dropdown,
		FileInput,
		Toggle,
		OptionSelector
	},
	props: {
		scanTypes: {
			type: Array,
			default: () => []
		},
		actionTypes: {
			type: Array,
			default: () => []
		},
		loginTemplates: {
			type: Array,
			default: () => []
		},
		maxUrls: Number
	},
	setup () {
		return {
			showSnackbar,
			siteScannerVersion,
			defaultBrandInSelect,
			clientMultiBranded,
			singleBrand,
			brandOptions
		}
	},
	data () {
		return {
			today,
			time24regex: /^([01]\d|2[0-3]):([0-5]\d)$/,
			serverTimeInfo: {
				currentServerTime: null,
				serverTimezone: null,
				utcOffsent: null
			},
			siteScan: {
				scanName: '',
				brandId: defaultBrandInSelect.value,
				emailAddress: '',
				scanType: 0,
				scanSchedule: 1,
				pausePeriodPerUrl: 0,
				scanStartDate: new Date(),
				scanExcludeBeforeDate: new Date(),
				scanRunTime: '00:01',
				scanFrequency: 1,
				scanDayOfWeek: 1,
				scanDayOfMonth: 1,
				domain: null,
				sitemapFile: null,
				sitemapUrl: null,
				skipDays: '45',
				deepCrawl: false,
				buttonSelector: null,
				allowedDomains: [],
				followRobotsTxt: false,
				pageTimeout: 5000,
				headless: true,
				loginTemplate: null
			},
			showAdvancedFeatures: false,
			advancedFeatureActions: [],
			showRobotsTxtToggle: false
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		scanTypeItems () {
			return this.scanTypes.map(scanType => ({
				text: scanType.scanTypeDescription,
				value: scanType.id
			}))
		},
		scanScheduleItems () {
			return [
				{ text: 'Scan Now', value: 1 },
				{ text: 'Schedule Single Scan', value: 2 },
				{ text: 'Schedule Recurring Scan', value: 3 }
			]
		},
		scanFrequencyItems () {
			return [
				{ text: 'Weekly', value: 2 },
				{ text: 'Monthly', value: 3 },
				{ text: 'Quarterly', value: 4 }
			]
		},
		dayItems () {
			return [
				{ text: 'Monday', value: 1 },
				{ text: 'Tuesday', value: 2 },
				{ text: 'Wednesday', value: 3 },
				{ text: 'Thursday', value: 4 },
				{ text: 'Friday', value: 5 },
				{ text: 'Saturday', value: 6 },
				{ text: 'Sunday', value: 7 }
			]
		},
		dayOfMonthItems () {
			return Array.from({ length: 28 }, (_, i) => i + 1).map(day => ({
				text: day,
				value: day
			}))
		},
		scanNowValue () {
			return 1
		},
		scheduleSingleScanValue () {
			return 2
		},
		scheduleRecurringScanValue () {
			return 3
		},
		isScheduleScan () {
			return this.siteScan.scanSchedule === this.scheduleSingleScanValue || this.siteScan.scanSchedule === this.scheduleRecurringScanValue
		},
		pageTimeoutItems () {
			const items = []
			for (let i = 1; i < 11; i++) {
				items.push({ text: i, value: i * 1000 })
			}
			return items
		},
		singleUrlScanType () {
			return 0
		},
		sitemapFileScanType () {
			return 1
		},
		sitemapUrlScanType () {
			return 2
		},
		indexedSitemapUrlScanType () {
			return 3 // TBC
		},
		weeklyScanFrequency () {
			return this.siteScan.scanFrequency === 2
		},
		monthlyScanFrequency () {
			return this.siteScan.scanFrequency === 3
		},
		quarterlyScanFrequency () {
			return this.siteScan.scanFrequency === 4
		},
		siteScannerFullAccess () {
			return this.productAreaPermission(SITE_SCANNER_FULL_ACCESS)
		},
		scanFromDate () {
			return format(this.siteScan.scanStartDate, DATE_FORMAT_DATE_PICKER)
		},
		scanExcludeBeforeDate () {
			return format(this.siteScan.scanExcludeBeforeDate, DATE_FORMAT_DATE_PICKER)
		},
		firstScanDate () {
			const regex = new RegExp(this.time24regex)
			const scanRunTimeValid = regex.test(this.siteScan.scanRunTime)
			if (!scanRunTimeValid) return ''
			const serverDate = new Date(new Date(this.serverTimeInfo.currentServerTime).toDateString())
			const startRunDate = new Date(new Date(this.siteScan.scanStartDate).toDateString())
			const dateToCompare = startRunDate > serverDate ? startRunDate : serverDate
			const scanRunTimeHour = parseInt(this.siteScan.scanRunTime.substring(0, 2))
			const scanRunTimeMins = parseInt(this.siteScan.scanRunTime.substring(3, 5))
			if (this.weeklyScanFrequency) {
				let dayDiff = (this.siteScan.scanDayOfWeek - dateToCompare.getDay() < 0)
					? this.siteScan.scanDayOfWeek - dateToCompare.getDay() + 7
					: this.siteScan.scanDayOfWeek - dateToCompare.getDay()
				// Add a week if you picked 'today' but a time in the past
				if (this.currentServerTime > new Date(serverDate.setHours(scanRunTimeHour, scanRunTimeMins)).getTime() && startRunDate < serverDate && dayDiff === 0) {
					dayDiff = dayDiff + 7
				}
				const weekDate = new Date(dateToCompare.setDate(dateToCompare.getDate() + dayDiff))
				const weekDateTime = new Date(weekDate.setHours(scanRunTimeHour, scanRunTimeMins))
				return new Date(weekDateTime.setHours(scanRunTimeHour, scanRunTimeMins))
			}
			if (this.monthlyScanFrequency) {
				const targetDateTime = new Date(new Date(dateToCompare.getFullYear(), dateToCompare.getMonth(),
					this.siteScan.scanDayOfMonth).setHours(scanRunTimeHour, scanRunTimeMins))
				if (targetDateTime.getTime() > this.currentServerTime && targetDateTime.getTime() > startRunDate.getTime()) {
					return targetDateTime
				} else {
					const dayDiff = this.siteScan.scanDayOfMonth - dateToCompare.getDate()
					return dayDiff < 0 || (dayDiff === 0 && new Date(serverDate.setHours(scanRunTimeHour, scanRunTimeMins)).getTime() < this.currentServerTime)
						? new Date(new Date(new Date(dateToCompare.setHours(scanRunTimeHour,
							scanRunTimeMins)).setMonth(dateToCompare.getMonth() + 1)).setDate(this.siteScan.scanDayOfMonth))
						: new Date(new Date(dateToCompare.setHours(scanRunTimeHour, scanRunTimeMins)).setDate(this.siteScan.scanDayOfMonth))
				}
			}
			if (this.quarterlyScanFrequency) {
				const q1 = new Date(new Date(dateToCompare.getFullYear(), 0, this.siteScan.scanDayOfMonth).setHours(scanRunTimeHour, scanRunTimeMins))
				const q2 = new Date(new Date(dateToCompare.getFullYear(), 3, this.siteScan.scanDayOfMonth).setHours(scanRunTimeHour, scanRunTimeMins))
				const q3 = new Date(new Date(dateToCompare.getFullYear(), 6, this.siteScan.scanDayOfMonth).setHours(scanRunTimeHour, scanRunTimeMins))
				const q4 = new Date(new Date(dateToCompare.getFullYear(), 9, this.siteScan.scanDayOfMonth).setHours(scanRunTimeHour, scanRunTimeMins))
				if (dateToCompare.getTime() > q1.getTime() && dateToCompare.getTime() < q2.getTime())	return q2
				if (dateToCompare.getTime() > q2.getTime() && dateToCompare.getTime() < q3.getTime())	return q3
				if (dateToCompare.getTime() > q3.getTime() && dateToCompare.getTime() < q4.getTime())	return q4
				else return new Date(q1.setFullYear(q1.getFullYear() + 1))
			} else return ''
		},
		siteScannerV2 () {
			const siteScannerVersion2 = 2
			return siteScannerVersion.value === siteScannerVersion2
		},
		currentServerTimeFormatted () {
			return format(new Date(new Date(this.serverTimeInfo?.currentServerTime).getTime()), DATE_TIME_FORMAT_NO_SECONDS)
		},
		scanDate () {
			const scanDatePart = new Date(this.siteScan.scanStartDate)
			const scanTime = this.siteScan.scanRunTime.split(':')
			scanDatePart.setHours(scanTime[0], scanTime[1])
			return scanDatePart
		},
		scanDateFormatted () {
			return format(new Date(this.scanDate), DATE_TIME_FORMAT_NO_SECONDS)
		},
		pausePeriodItems () {
			const items = []
			for (let i = 0; i < 16; i++) {
				items.push({ text: i, value: i })
			}
			return items
		},
		loginTemplateItems () {
			return this.loginTemplates.map(loginTemplate => ({
				text: loginTemplate.templateName,
				value: loginTemplate.templateGuid,
				brandId: loginTemplate.brandId
			})).filter(loginTemplate => loginTemplate.brandId === this.siteScan.brandId || loginTemplate.brandId === 0 || this.siteScan.brandId === 0)
		},
		loginTemplateDescription () {
			const loginTemplate = this.loginTemplates.find(template => template.templateGuid === this.siteScan.loginTemplate)
			return loginTemplate ? loginTemplate.templateDescription : ''
		}
	},
	async created () {
		await waitForLoad()
		const timezoneResponse = await getServerTimezoneInfo()
		await this.setDefaultBrand()
		this.serverTimeInfo = timezoneResponse.data
		if (this.scanTypes.length === 0 || this.actionTypes.length === 0 || this.maxUrls === undefined) {
			this.backToOverview()
		}
	},
	methods: {
		async setDefaultBrand () {
			this.siteScan.brandId = defaultBrandInSelect.value
		},
		async requestSiteScan () {
			this.requestSchedule()
		},
		customToISOString (date) {
			const year = date.getFullYear()
			const month = String(date.getMonth() + 1).padStart(2, '0')
			const day = String(date.getDate()).padStart(2, '0')
			const hours = String(date.getHours()).padStart(2, '0')
			const minutes = String(date.getMinutes()).padStart(2, '0')
			const seconds = String(date.getSeconds()).padStart(2, '0')
			return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
		},
		async requestSchedule () {
			const requestScanForm = new FormData()
			requestScanForm.append('ScanName', this.siteScan.scanName)
			requestScanForm.append('BrandId', this.siteScan.brandId)
			requestScanForm.append('EmailAddressToNotify', this.siteScan.emailAddress)
			requestScanForm.append('ScheduledScanDateAndTime', this.siteScan.scanSchedule === this.scanNowValue ? this.customToISOString(await this.requestNowScanDate()) : this.siteScan.scanFrequency === 1 ? this.customToISOString(this.scanDate) : this.customToISOString(this.firstScanDate))
			requestScanForm.append('DeepCrawl', this.setDeepCrawl())
			if (this.showAdvancedFeatures) {
				if (this.siteScan.buttonSelector) {
					this.generateActions()
					this.advancedFeatureActions.forEach(action => {
						requestScanForm.append('Actions', JSON.stringify(action))
					})
				}
				requestScanForm.append('UseRobots', this.siteScan.followRobotsTxt)
				requestScanForm.append('AlternativeDomains', this.siteScan.allowedDomains.length >= 1 ? this.siteScan.allowedDomains.join(',') : null)
			}
			if (this.siteScan.scanType === this.sitemapFileScanType && this.siteScan.sitemapFile) requestScanForm.append('SiteMapFile', this.siteScan.sitemapFile)
			if (this.siteScan.scanType === this.sitemapUrlScanType && this.siteScan.sitemapUrl) requestScanForm.append('SiteMapUrl', this.siteScan.sitemapUrl)
			if (this.siteScan.scanType === this.singleUrlScanType && this.siteScan.domain) requestScanForm.append('Domain', this.siteScan.domain)
			requestScanForm.append('SkipDays', this.siteScan.scanExcludeBeforeDate.toISOString())
			requestScanForm.append('ScanType', this.siteScan.scanType)
			requestScanForm.append('ScanDelayPeriod', this.siteScan.pausePeriodPerUrl)
			requestScanForm.append('PageTimeout', this.siteScan.pageTimeout)
			if (this.siteScan.scanSchedule === this.scheduleRecurringScanValue) {
				if (this.weeklyScanFrequency)	requestScanForm.append('Day', this.siteScan.scanDayOfWeek)
				else requestScanForm.append('Day', this.siteScan.scanDayOfMonth)
			}
			requestScanForm.append('Frequency', this.siteScan.scanFrequency)
			requestScanForm.append('Headless', this.siteScan.headless ?? true)
			if (this.siteScan.loginTemplate) requestScanForm.append('LoginTemplateGuid', this.siteScan.loginTemplate)
			await requestScheduledScan(requestScanForm).then(() => {
				this.showSnackbar('Scan requested successfully')
				this.backToOverview()
			}).catch(e => {
				this.showSnackbar({ color: 'red', text: e.response.data[0] })
			})
		},
		backToOverview () {
			this.$router.push({ name: 'cookie-scanner' })
		},
		selectFile (file) {
			this.siteScan.sitemapFile = file
		},
		updateScanFromDate (date) {
			this.siteScan.scanStartDate = new Date(date)
		},
		updateScanExcludeBeforeDate (date) {
			this.siteScan.scanExcludeBeforeDate = new Date(date)
		},
		dateStringWithTimezone (dateToConvert) {
			if (dateToConvert !== '')	{
				const date = new Date(dateToConvert)
				const formattedDate = format(new Date(date.getTime()), DATE_TIME_FORMAT_NO_SECONDS)
				return formattedDate + ' ' + this.serverTimeInfo.serverTimezone
			}
			return '\'Scan Run Time\' not set ' + this.serverTimeInfo.serverTimezone
		},
		onScheduleScanChanged (value) {
			if (value === this.scheduleRecurringScanValue)	{
				this.siteScan.scanFrequency = 2
			} else {
				this.siteScan.scanFrequency = 1
			}
		},
		updateAllowedDomains (value) {
			this.siteScan.allowedDomains = value
		},
		setDeepCrawl () {
			if (this.siteScan.scanType !== this.singleUrlScanType) return false
			else return !this.siteScan.deepCrawl
		},
		async requestNowScanDate () {
			const timezoneResponse = await getServerTimezoneInfo()
			this.serverTimeInfo = timezoneResponse.data
			const newRequestNowDate = new Date(this.serverTimeInfo?.currentServerTime)
			newRequestNowDate.setSeconds(newRequestNowDate.getSeconds() + 10)
			return newRequestNowDate
		},
		generateActions () {
			const waitForElementActionType = this.actionTypes.find(actionType => actionType.actionTypeDescription === 'WaitForElement')
			const clickActionType = this.actionTypes.find(actionType => actionType.actionTypeDescription === 'Click')
			const waitActionType = this.actionTypes.find(actionType => actionType.actionTypeDescription === 'Wait')

			const actions = [
				{
					type: waitForElementActionType.id,
					selector: this.siteScan.buttonSelector,
					timeout: 5000
				},
				{
					type: clickActionType.id,
					selector: this.siteScan.buttonSelector,
					force: false
				},
				{
					type: waitActionType.id,
					timeout: 5000
				}
			]
			this.advancedFeatureActions = actions
		}
	}
}
</script>
